/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_kqYN4Bpx2",
    "aws_user_pools_web_client_id": "1kobvo5q5bgk6ffrbioli8cbvd",
    "oauth": {},
    "aws_cognito_login_mechanisms": [
        "EMAIL"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": "8",
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE"
        ]
    },
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://d1v9ppymxl.execute-api.ap-northeast-1.amazonaws.com/dev",
            "region": "ap-northeast-1"
        },
        {
            "name": "usersApi",
            "endpoint": "https://peerapp-study-apis.thinkss-kenkobus.net",
            "region": "ap-northeast-1"
        }
    ],
    "Auth": {
        "identityPoolId": "ap-northeast-1:3037ee64-3eea-4408-9ba3-d91b627bb1ad",
        "region": "ap-northeast-1",
        "userPoolId": "ap-northeast-1_kqYN4Bpx2",
        "userPoolWebClientId": "1kobvo5q5bgk6ffrbioli8cbvd",
        "mandatorySignIn": true
    },
    "API": {
        "endpoints": [
            {
                "name": "usersApi",
                "endpoint": "https://peerapp-study-apis.thinkss-kenkobus.net",
                "region": "ap-northeast-1"
            }
        ]
    },
    "Storage": {
        "region": "ap-northeast-1"
    },
    "Analytics": {
        "disabled": true
    }
};


export default awsmobile;
