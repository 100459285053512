import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store'

import { Logger } from 'aws-amplify'
import { Hub } from '@aws-amplify/core'
import Auth from '@aws-amplify/auth'

const logger = new Logger('router')

Vue.use(VueRouter)

let user = null

const getUser = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser()
    if (user && user.signInUserSession) {
      store.commit("setUser", user)
      return user
    }
  } catch (error) {
    logger.info(error);
    store.commit("setUser", null)
    return null
  }
}

Hub.listen('auth', async (data) => {
  logger.debug('Hub event happened!', data.payload.event)
  switch (data.payload.event) {
    case 'signIn':
      user = await getUser();
      router.push({ path: '/' })
      break
    case 'signOut':
      user = null;
      store.commit('setUser', null)
      router.push({ path: '/auth/signIn' })
      break
    case 'signUp':
    case 'signIn_failure':
    case 'configured':
      logger.debug('Hub event: ignored')
      break
    default:
      logger.debug('Hub event: unknown ignored')
  }
})

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import('@/auth/AuthView'),
    children: [{
      path: 'signIn',
      name: 'auth_SignIn',
      component: () => import('@/auth/components/SignIn')
    }]
  },
  {
    path: '/signout',
    beforeEnter (to, from, next) {
      Auth.signOut()
        .then(() => {
          logger.debug('sign out success')
          next('/auth/signin')
        })
        .catch(err => {
          this.setError(err)
          next('/auth/signin')
        })
    }
  },
  {
    path: '/consent',
    name: 'consent',
    component: () => import('@/pages/Consent'),
    children: [
      {
        path: '',
        redirect: 'desc'
      },
      {
        path: 'desc',
        name: 'desc',
        component: () => import('@/pages/consent/Desc')
      },
      {
        path: 'form',
        name: 'form',
        component: () => import('@/pages/consent/Form')
      },
      {
        path: 'post',
        name: 'formPost',
        component: () => import('@/pages/consent/Post')
      }
    ]
  },
  {
    path: '/dashboard',
    redirect: '/home/dashboard'
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/pages/Home'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        redirect: 'dashboard'
      },
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () => import('@/pages/Dashboard')
      },
      {
        path: 'cognito_admins',
        name: 'cognitoAdmins',
        component: () => import('@/pages/CognitoAdmins')
      },
      {
        path: 'users',
        name: 'users',
        component: () => import('@/pages/Users')
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeResolve(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    user = await getUser()
    // console.log(user);
    if (!user) {
      return next({
        path: "/auth/signIn",
        query: {
          redirect: to.fullPath,
        },
      })
    }
    return next()
  }
  return next()
})

export default router
