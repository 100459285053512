import Vue from 'vue'
import Vuex from 'vuex'

// import moment from 'moment-timezone'
import AWS from 'aws-sdk'
import { API, Auth, Logger } from 'aws-amplify'
// import _ from 'lodash'
import awsexports from '../aws-exports'
API.configure(awsexports)

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'
const logger = new Logger('store')

const DYNAMODB_PREFIX = process.env.VUE_APP_DYNAMODB_PREFIX
const DYNAMODB_POSTFIX = process.env.VUE_APP_DYNAMODB_POSTFIX

const dbTbl = (t) => {
  return DYNAMODB_PREFIX + t + DYNAMODB_POSTFIX
}

const state = {
  user: null,
  userId: null,
  userVerification: [],
  sidebarToggled: true,
  admins: [],

  consented: {}
}
const mutations = {
  setUser (state, user) {
    state.user = user
  },
  setUserId (state, userId) {
    state.userId = userId
  },
  setUserVerification (state, data) {
    state.userVerification = data
  },
  setSidebarToggled (state, data) {
    state.sidebarToggled = !!data
  },
  setAdmins (state, { admins }) {
    logger.debug('store:mutations:setAdmins:', state.admins, admins)
    state.admins = admins
  },
  setConsentedCase (state, consent) {
    logger.debug('store::setConsentedCase', consent)
    state.consented = consent
  }
}
const getters = {
  user: state => state.user,
  userId: state => state.userId,
  userVerification: state => state.userVerification,
  isSignIn: state => state.user !== null,
  sidebarToggled: state => !!state.sidebarToggled
}
const actions = {
  toggleSidebar ({ commit, state }) {
    debug && logger.debug('store::toggleSidebar', commit, state)
    commit('setSidebarToggled', !state.sidebarToggled)
  },
  async fetchConsents ({ commit, state }, { limit, lastEvaluatedKey }) {
    debug && logger.debug('store::fetchConsents', commit, state)
    logger.debug('store::fetchConsents', limit, lastEvaluatedKey)
    const params = {
      TableName: dbTbl('consents'),
      Limit: limit || 100
    }
    lastEvaluatedKey && (params.ExclusiveStartKey = lastEvaluatedKey)

    debug && logger.debug('store::fetchConsents', params)
    try {
      const credentials = await Auth.currentCredentials()
      AWS.config.update({ region: 'ap-northeast-1' })
      const docClient = new AWS.DynamoDB.DocumentClient({
        apiVersion: '2013-04-01',
        credentials: Auth.essentialCredentials(credentials)
      })
      const data = await docClient.scan(params).promise()
      logger.debug(data.lastEvaluatedKey)
      return data
    } catch (error) {
      logger.error(error)
      throw error
    }
  },
  async findConsents ({ commit, state }, { consentId }) {
    debug && logger.debug('store::fetchConsents', commit, state)
    const params = {
      TableName: dbTbl('consents'),
      Key: { consentId: consentId }
    }
    try {
      const credentials = await Auth.currentCredentials()
      AWS.config.update({ region: 'ap-northeast-1' })
      const docClient = new AWS.DynamoDB.DocumentClient({
        apiVersion: '2013-04-01',
        credentials: Auth.essentialCredentials(credentials)
      })
      const data = await docClient.get(params).promise()
      return data.Item
    } catch (error) {
      logger.error(error)
      throw error
    }
  },
  async editConsent ({ commit, state }, { consentId, name, value }) {
    debug && logger.debug('store::editConsent', commit, state)
    logger.debug('editConsent:', consentId, name, value)

    let exp = 'SET #updatedKey = :updatedValue'

    // let curTime = moment().format('x')

    let params = {
      TableName: dbTbl('consents'),
      Key: {
        consentId: consentId
      },
      ExpressionAttributeNames: {
        '#updatedKey': name
      },
      ExpressionAttributeValues: {
        ':updatedValue': value || null
      },
      UpdateExpression: exp
    }
    logger.debug(params)
    try {
      let credentials = await Auth.currentCredentials()
      AWS.config.update({ region: 'ap-northeast-1' })
      const docClient = new AWS.DynamoDB.DocumentClient({
        apiVersion: '2013-04-01',
        credentials: Auth.essentialCredentials(credentials)
      })
      let data = await docClient.update(params).promise()
      return data
    } catch (error) {
      logger.error(error)
      throw error
    }
  },
  async postConsent ({ commit, state }, { name, nameKana, email }) {
    debug && logger.debug('store::editConsent', commit, state)
    const data = { name: name, nameKana: nameKana, email: email }
    logger.debug(API)

    let res = await API.post('usersApi', '/consents/new', { response: true, body: data })
    logger.debug('postConsent', res)
    if (res.status !== 200) {
      logger.error('response error', res)
      throw new Error('response error')
    } else {
      if (res.data.result !== 'OK') {
        logger.error('response error', res.data.errorMessage)
        throw new Error(res.data.errorMessage || res.data)
      }
    }
    logger.debug('store::postConsent', res.data)
    commit('setConsentedCase', res.data)
    return res.data
  },
  fetchCognitoAdmins ({ commit, state }, { paginationToken }) {
    debug && logger.debug('store::editConsent', commit, state)
    var params = {
      UserPoolId: process.env.VUE_APP_COGNITO_Admins_UserPoolId,
      PaginationToken: paginationToken,
      Limit: 60
    }
    return Auth.currentCredentials()
      .then(credentials => {
        AWS.config.update({ region: 'ap-northeast-1' })
        let cognito = new AWS.CognitoIdentityServiceProvider({
          apiVersion: '2016-04-18',
          credentials: Auth.essentialCredentials(credentials)
        })
        return cognito.listUsers(params).promise()
      })
      .then(data => {
        commit('setAdmins', { admins: data.Users })
        return data
      })
  },
  createCognitoAdmin ({ commit, state }, { newUser }) {
    debug && logger.debug('store::editConsent', commit, state)
    if (!newUser) {
      throw new Error('invalid parameter ' + newUser)
    }
    console.log('Congnito Key:' + newUser)
    let params = {
      UserPoolId: process.env.VUE_APP_COGNITO_Admins_UserPoolId,
      Username: newUser.username,
      TemporaryPassword: newUser.password,
      MessageAction: 'SUPPRESS',
      UserAttributes: [{
        Name: 'email',
        Value: newUser.email
      }, {
        Name: 'nickname',
        Value: newUser.nickname
      }]
    }
    return Auth.currentCredentials()
      .then(credentials => {
        AWS.config.update({
          region: 'ap-northeast-1'
        })
        let cognito = new AWS.CognitoIdentityServiceProvider({
          apiVersion: '2016-04-18',
          credentials: Auth.essentialCredentials(credentials)
        })
        return cognito.adminCreateUser(params).promise()
      })
      .then(data => {
        logger.debug(data)
        return data
      })
  },
  updatePasswordCognitoAdmin ({ commit, state }, { username, newPassword }) {
    debug && logger.debug('store::editConsent', commit, state)
    if (!username || !newPassword) {
      throw new Error('invalid parameter ' + username + ':' + newPassword)
    }
    console.log('Congnito Key:' + username)
    let params = {
      UserPoolId: process.env.VUE_APP_COGNITO_Admins_UserPoolId,
      Username: username,
      Password: newPassword,
      Permanent: true
    }
    return Auth.currentCredentials()
      .then(credentials => {
        AWS.config.update({
          region: 'ap-northeast-1'
        })
        let cognito = new AWS.CognitoIdentityServiceProvider({
          apiVersion: '2016-04-18',
          credentials: Auth.essentialCredentials(credentials)
        })
        return cognito.adminSetUserPassword(params).promise()
      })
      .then(data => {
        logger.debug(data)
        return data
      })
  },
  deleteCognitoAdmin ({ commit, state }, { username }) {
    debug && logger.debug('store::editConsent', commit, state)
    if (!username) {
      throw new Error('invalid parameter ' + username)
    }
    console.log('Congnito Key:' + username)
    let params = {
      UserPoolId: process.env.VUE_APP_COGNITO_Admins_UserPoolId,
      Username: username
    }
    return Auth.currentCredentials()
      .then(credentials => {
        AWS.config.update({
          region: 'ap-northeast-1'
        })
        let cognito = new AWS.CognitoIdentityServiceProvider({
          apiVersion: '2016-04-18',
          credentials: Auth.essentialCredentials(credentials)
        })
        return cognito.adminDeleteUser(params).promise()
      })
      .then(data => {
        logger.debug(data)
        return data
      })
  }
}


export default new Vuex.Store({
  state: state,
  mutations,
  actions: actions,
  getters: getters
  /*
  modules: {
    amplifyStore
  }
  */
})
